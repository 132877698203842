const Blog = () => {
    return (
        <>
            <div className="container">
                <h1>404 Page Not Found</h1>
                <p>The page you requested was not found.</p>	</div>
        </>
    )
}

export default Blog; 