import About1 from '../../assets/images/about1.webp';
import { Link } from 'react-router-dom';


const TermCondition = () => {
    return (
        <>
            <section>
                <div className="BreadArea" style={{ backgroundImage: `url(${About1})` }}>
                    <div className="container">
                        <h2>Terms &amp; Condition</h2>
                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li className="active"><a href="#">Terms &amp; Condition</a></li>
                        </ul>

                    </div>
                </div>
            </section>
            <section>
                <div className="intro-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <h3 className="intro-title">Terms &amp; Condition</h3>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="intro-content">
                                    <p>CRY believes that every child has a right to a happy, healthy and creative
                                        childhood. We believe in enabling people to pledge their unique strengths and commitments to secure,
                                        protect and honor the rights of children. </p>

                                    <p>As a child rights organisation, CRY – Child Rights and You, is committed to the principle that all children,
                                        regardless of all dimensions and layers of discrimination, are entitled to their rights as defined by the
                                        United Nations Convention of Child Rights (UNCRC) Charter and the Constitution of India, including an
                                        equal right to protection from all kinds of abuse, exploitation, violence and neglect. </p>

                                    <p>CRY’s Child Protection Policy stands for its commitment and responsibilities towards safeguarding
                                        children and to protect them from all kinds of harm and abuse, keeping the best interest of the child in
                                        perspective. It also makes clear that any form of harm and abuse of children is unacceptable and nonnegotiable to CRY, and any violation of the policy, whether intentional or unintentional, will invite
                                        appropriate disciplinary action and judicial procedures as per this policy, other related policies of the
                                        organisation and law of the land</p>

                                    <p>To promulgate and reinforce the organizations commitment for safeguarding children</p>
                                    <p>To ensure that the safety, care, protection and welfare of the child are imperative to practice and
                                        adopt in whatever we do.</p>

                                    <p>To provide a protection mechanism to all children covered through the development initiatives of
                                        CRY </p>

                                    <p>To provide a mechanism to ensure all staff members of CRY and associates are informed,
                                        empowered and self equipped to ensure safety and protection of children  </p>

                                    <p>Child Protection Policy of CRY
                                        CHILD PROTECTION POLICY – CHILD RIGHTS AND YOU (CRY)
                                        POLICY STATEMENT: CRY believes that every child has a right to a happy, healthy and creative
                                        childhood. We believe in enabling people to pledge their unique strengths and commitments to secure,
                                        protect and honor the rights of children.
                                        As a child rights organisation, CRY – Child Rights and You, is committed to the principle that all children,
                                        regardless of all dimensions and layers of discrimination, are entitled to their rights as defined by the
                                        United Nations Convention of Child Rights (UNCRC) Charter and the Constitution of India, including an
                                        equal right to protection from all kinds of abuse, exploitation, violence and neglect.
                                        CRY’s Child Protection Policy stands for its commitment and responsibilities towards safeguarding
                                        children and to protect them from all kinds of harm and abuse, keeping the best interest of the child in
                                        perspective. It also makes clear that any form of harm and abuse of children is unacceptable and nonnegotiable to CRY, and any violation of the policy, whether intentional or unintentional, will invite
                                        appropriate disciplinary action and judicial procedures as per this policy, other related policies of the
                                        organisation and law of the land.
                                        Section 1. PURPOSE OF THE POLICY
                                        1. To promulgate and reinforce the organizations commitment for safeguarding children
                                        2. To ensure that the safety, care, protection and welfare of the child are imperative to practice and
                                        adopt in whatever we do.
                                        3. To provide a protection mechanism to all children covered through the development initiatives of
                                        CRY
                                        4. To establish a standard code of conduct and standard procedures for all staff members and
                                        associates of CRY to ensure that no harm or abuse is caused to any child.
                                        5. To provide a mechanism to ensure all staff members of CRY and associates are informed,
                                        empowered and self equipped to ensure safety and protection of children
                                        Section 2. KEY DEFINITIONS
                                        1. Child1
                                        : In the policy, the child is defined as a person up to the age of 18 years.
                                        2. Child Abuse2
                                        : Child abuse is defined as all forms of physical abuse, emotional ill-treatment,
                                        sexual abuse and exploitation, neglect or negligent treatment, commercial or other exploitation
                                        1 The definition is based on the United Nations Convention on the Rights of the Child
                                        2 Child Protection Policy of CRY
                                        of a child and includes any action that result in actual or potential harm to a child. Child abuse
                                        may be a deliberate act or it may be failing to act to prevent harm. Child abuse consists of
                                        anything which individuals, institutions or processes do or fail to do, intentionally or
                                        unintentionally, which harms a child or damages their prospect of safe and healthy
                                        development.</p>
                                    <p>. Child Protection: Child protection is the protection of children from any violence, exploitation,
                                        abuse and neglect. Within the ambit of this policy, it would mean the measures, activities and
                                        safeguards that CRY would be taking to protect children from both intentional and unintentional
                                        harm. </p>
                                    <p>Staff Member of CRY: Staff member of CRY would include all employees on the pay-roll, part
                                        time or contractual duty or deputation of CRY whether within CRY office premises or on field
                                        duty / project visit or elsewhere.</p>
                                    <p>Associate of CRY: This would include all consultants working with CRY, all volunteers, interns,
                                        tele-callers, agency staff, contractors, vendors and donors visiting CRY offices or work areas. This
                                        would also include all staff of CRY project partners and all volunteers and interns who would
                                        work with children and /or have access to and are party to CRY’s program and child data.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default TermCondition;