import Header from '../component/homecomponent/Header'
import Footer from '../component/homecomponent/Footer';
import Home from '../component/homecomponent/Home';
import About from '../component/homecomponent/About';
import Shop from '../component/pages/Shop';
import OurProgram from '../component/pages/OurProgram';
import Crowdfunding from '../component/pages/Crowdfunding';
import Blog from '../component/pages/Blog';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Contact from '../component/pages/Contact';
import TermCondition from '../component/pages/TermCondition';
import Privacy from '../component/pages/Privacy';


const Router = () => {

    return (
        <>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/shop' element={<Shop />} />
                    <Route path='/our-programs' element={<OurProgram />} />
                    <Route path='/crowdfunding' element={<Crowdfunding />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/terms' element={<TermCondition />} />
                    <Route path='/privacy' element={<Privacy />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </>

    )
}

export default Router;