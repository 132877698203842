import About1 from '../../assets/images/about1.webp';
import { Link } from 'react-router-dom';

const About = () => {

    const animationStyle = {
        visibility: 'visible',
        animationName: 'zoomIn',
        animationDuration: '1s',
        animationFillMode: 'forwards',
    };

    const bounceInRightStyle = {
        visibility: 'visible',
        animationDuration: '1.5s',
        animationName: 'bounceInRight'
    }

    const bounceInLeftStyle = {
        visibility: 'visible',
        animationDuration: '1.5s',
        animationName: 'bounceInLeft'

    }

    return (
        <>
            <section>
                <div className="BreadArea" style={{ backgroundImage: `url(${About1})` }} >
                    <div className="container">
                        <h2>About Us</h2>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li className="active"><a href="#">About us</a></li>
                        </ul>

                    </div>
                </div>
            </section>

            <section className="about-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 wow bounceInLeft" data-wow-duration="1.5s" style={bounceInLeftStyle}>
                            <div className="about-content">
                                <h2>About US</h2>
                                <p>Winklix Foundation, a leading NGO based in Delhi since 2020, is committed to transforming
                                    lives through its dedicated efforts in child healthcare, human welfare, and animal welfare.
                                    The foundation provides essential medical care for children, supports vulnerable populations
                                    with education and vocational training, and ensures the well-being of animals through rescue
                                    operations and awareness programs. United by a shared vision, Winklix Foundation aims to re-define
                                    humanity in India by fostering compassion, empathy, and social responsibility. Their holistic
                                    approach addresses immediate needs while working towards long-term, sustainable improvements,
                                    embodying a new standard of care and kindness.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 wow bounceInRight" data-wow-duration="1.5s" style={bounceInRightStyle}>
                            <div className="about-left">
                                <img src={require('../../assets/images/about.webp')} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-12 wow zoomIn" style={animationStyle}>
                            <div className="about-img">
                                <img src={require('../../assets/images/OUR-MISSION-svg.webp')} alt="our mission" className="img-responsive" />
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-6 col-xs-12 wow bounceInRight" data-wow-duration="1.5s" style={bounceInRightStyle}>
                            <div className="about-title">
                                <h1>Our<span> Mission</span></h1>
                                <p>To enable people to take responsibility for the situation of the deprived Indian child and so motivate them to seek resolution through individual and collective action thereby enabling children to realise their full potential
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="about">
                <div className="container">
                    <div className="row">

                        <div className="col-md-8 col-sm-6 col-xs-12 wow bounceInLeft" data-wow-duration="1.5s" style={bounceInLeftStyle}>
                            <div className="about-title">
                                <h1>Our<span> Vision</span></h1>
                                <p>A happy, healthy and creative child whose rights are protected and honoured in a society that is built on respect for dignity, justice and equity for all.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 wow zoomIn" style={animationStyle}>
                            <div className="about-img">
                                <img src={require('../../assets/images/VISION-svg.webp')} alt="vision" className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;