import './App.css';
import './assets/css/styles.css';
import './assets/css/style.css';
import './assets/css/bootstrap.min.css';
import Router from './router/routes';

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
