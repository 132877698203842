import About1 from '../../assets/images/about1.webp';


const Crowdfunding = () => {

    const animationStyle = {
        visibility: 'visible',
        marginBottom: '80px',
        animationName: 'zoomIn',
        animationDuration: '1s',
        animationFillMode: 'forwards'
    };

    return (
        <>
            <section>
                <div className="BreadArea" style={{ backgroundImage: `url(${About1})` }}>
                    <div className="container">
                        <h2>Crowdfunding</h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li className="active"><a href="#">Crowdfunding</a></li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-6 col-xs-12 wow zoomIn" style={animationStyle}>
                            <img src={require('../../assets/images/template-2.webp')} alt="vision" className="img-responsive" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Crowdfunding;