import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Keyboard } from 'swiper/modules';
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import "swiper/css";
import "swiper/css/pagination";

const Home = () => {

    const style = {
        visibility: 'visible',
        animationDuration: '0.5s',
    };

    const bounceInLeftStyle = {
        visibility: 'visible',
        animationDuration: '1.5s',
        animationName: 'bounceInLeft'
    }

    const bounceInRightStyle = {
        visibility: 'visible',
        animationDuration: '1.5s',
        animationName: 'bounceInRight'
    }

    const zoomInStyle = {
        visibility: 'visible',
        animationName: 'zoomIn'
    }
    return (
        <>
            <Helmet>
                <meta name="description" content="Winklix foundation is leading NGO empowering human healthcare and welfare in India , providing livelihoods and benefits to needy one ." />
                <meta
                    name="keywords"
                    content="winklix foundation, winklix, ngo in india, top ngo in india, top ngo in delhi"
                />
            </Helmet>

            <section className="banner-section">
                <section className="banner">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="SliderArea">
                                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="item active">
                                            <img src={require('../../assets/images/banner.webp')} alt="banner" style={{ height: '94vh' }} />
                                            <div className="SlideContent animate fadeInUp animated" data-animate="fadeInUp" data-duration="2s" data-delay="0s">
                                                <h1>Let's Redefine Humanity<br />
                                                    Your Donation Can Help Others</h1>
                                                <div className="business-loan">
                                                    <div className="loan">
                                                        <ul>
                                                            <li>5+</li>
                                                            <li>Programs Launched</li>
                                                        </ul>
                                                    </div>
                                                    <div className="loan">
                                                        <ul>
                                                            <li>50+</li>
                                                            <li>Crowdfunding Supported</li>
                                                        </ul>
                                                    </div>
                                                    <div className="loan">
                                                        <ul>
                                                            <li>100 +</li>
                                                            <li>Life Impacted</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <a target="_blank" href="https://rzp.io/l/winklixfoundation">Donate</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <section className="about-section">
                <section className="about-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 wow bounceInLeft" data-wow-duration="1.5s" style={style}>
                                <div className="about-content">
                                    <h2>About US</h2>
                                    <p>"<b>Winklix Foundation</b> " has been set up in 2021 by young yet corporate professionals to uproot the grass work and bring change for Humanity , who are actually in need and are still not blessed by God .
                                        The statistics and problem is same all over India when it comes to less privileged ones be it in rural areas or urban areas .<br />
                                        We at Winklix aim to bring new tech innovation in the field of Healthcare , Child welfare , Women Empowerment , Animal Welfare , Environment Protection , School &amp; Education and more .
                                    </p>
                                    <Link className="readmore" to="/about">Read More</Link>
                                </div>
                            </div>
                            <div className="col-sm-6 wow bounceInRight" data-wow-duration="1.5s" style={style}>
                                <div className="about-left">
                                    <img src={require('../../assets/images/about.webp')} alt="about" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <section className="what-we-do">
                <div className="container">
                    <h3>What We Do</h3>
                    <div className="row">
                        <div className="col-sm-3 pd-15 wow bounceInLeft" data-wow-duration="1.5s" style={bounceInLeftStyle}>
                            <div className="we-do odd">
                                <img src={require('../../assets/images/w1.webp')} alt="" />
                                <h2>Health</h2>
                            </div>
                        </div>
                        <div className="col-sm-3 pd-15 wow zoomIn" style={zoomInStyle}>
                            <div className="we-do even">
                                <img src={require('../../assets/images/w2.webp')} alt="" />
                                <h2>Child welfare</h2>
                            </div>
                        </div>
                        <div className="col-sm-3 pd-15 wow zoomIn" style={zoomInStyle}>
                            <div className="we-do odd">
                                <img src={require('../../assets/images/w3.webp')} alt="" />
                                <h2>Women empowerment</h2>
                            </div>
                        </div>

                        <div className="col-sm-3 pd-15 wow bounceInRight" data-wow-duration="1.5s" style={bounceInRightStyle}>
                            <div className="we-do even">
                                <img src={require('../../assets/images/w4.webp')} alt="" />
                                <h2>Animal welfare</h2>
                            </div>
                        </div>
                        <div className="col-sm-3 pd-15 wow bounceInLeft" data-wow-duration="1.5s" style={bounceInRightStyle}>
                            <div className="we-do odd">
                                <img src={require('../../assets/images/w5.webp')} alt="" />
                                <h2>Environment Protection</h2>
                            </div>
                        </div>
                        <div className="col-sm-3 pd-15 wow zoomIn" style={zoomInStyle}>
                            <div className="we-do even">
                                <img src={require('../../assets/images/w6.webp')} alt="" />
                                <h2>School and education</h2>
                            </div>
                        </div>
                        <div className="col-sm-3 pd-15 wow zoomIn" style={zoomInStyle}>
                            <div className="we-do odd">
                                <img src={require('../../assets/images/w7.webp')} alt="" />
                                <h2>CSR</h2>
                            </div>
                        </div>

                        <div className="col-sm-3 pd-15 wow bounceInRight" data-wow-duration="1.5s" style={bounceInRightStyle}>
                            <div className="we-do even">
                                <img src={require('../../assets/images/w8.webp')} alt="" />
                                <h2>Crown funding</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="causes">
                <div className="container">
                    <h3>Causes you can raise funds for</h3>
                    <p>You have a cause , we have a solution . You can rely on us for the project you want to raise funds for .</p>
                    <div className="row">
                        <div className="causesarea">
                            <div className="causesbox">
                                <img src={require('../../assets/images/funds/c1.webp')} alt="" />
                                <h2>Medical</h2>
                            </div>
                            <div className="causesbox">
                                <img src={require('../../assets/images/funds/c2.webp')} alt="" />
                                <h2>Education</h2>
                            </div>
                            <div className="causesbox">
                                <img src={require('../../assets/images/funds/c3.webp')} alt="" />
                                <h2>Animal</h2>
                            </div>
                            <div className="causesbox">
                                <img src={require('../../assets/images/funds/c4.webp')} alt="" />
                                <h2>Welfare</h2>
                            </div>
                            <div className="causesbox">
                                <img src={require('../../assets/images/funds/c5.webp')} alt="" />
                                <h2>Creative</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="causesarea">
                            <div className="causesbox">
                                <img src={require('../../assets/images/funds/c6.webp')} alt="" />
                                <h2>Food &amp; Hunder</h2>
                            </div>
                            <div className="causesbox">
                                <img src={require('../../assets/images/funds/c7.webp')} alt="" />
                                <h2>Talent</h2>
                            </div>
                            <div className="causesbox">
                                <img src={require('../../assets/images/funds/c8.webp')} alt="" />
                                <h2>Community Development </h2>
                            </div>
                            <div className="causesbox">
                                <img src={require('../../assets/images/funds/c9.webp')} alt="" />
                                <h2>Women &amp; Girls</h2>
                            </div>
                            <div className="causesbox othercause">
                                {/* <img src="https://winklixfoundation.org/assets/images/icons/c9.webp" alt="" /> */}
                                <h2 className="others">Others</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="serv-sec">
                <div className="container">
                    <h3 className="offerTitle">This is the target that you can help us to achieve by end of 2022</h3>
                    <div className="row">
                        <div className="col-md-4 wow bounceInLeft" data-wow-duration="1.5s" style={bounceInLeftStyle}>
                            <div className="wrapper">
                                <span className="item-icon">
                                    <img className="serv-icon" src={require('../../assets/images/target/a1.webp')} alt="" />
                                </span>

                                <h5 className="serv-title">1000+</h5>
                                <p className="serv-para">children's to be impacted overall</p>
                            </div>
                        </div>
                        <div className="col-md-4 wow zoomIn" style={zoomInStyle}>
                            <div className="wrapper">
                                <span className="item-icon">
                                    <img className="serv-icon" src={require('../../assets/images/target/a2.webp')} alt="" />
                                </span>
                                <h5 className="serv-title">50000+</h5>
                                <p className="serv-para">funds to be raised for cause</p>
                            </div>
                        </div>
                        <div className="col-md-4 wow bounceInRight" data-wow-duration="1.5s" style={bounceInRightStyle}>
                            <div className="wrapper">
                                <span className="item-icon">
                                    <img className="serv-icon" src={require('../../assets/images/target/a3.webp')} alt="" />
                                </span>
                                <h5 className="serv-title">10000+</h5>
                                <p className="serv-para">Person to be immunized free of cost</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-4 wow bounceInLeft" data-wow-duration="1.5s" style={bounceInLeftStyle}>
                            <div className="wrapper">
                                <span className="item-icon">
                                    <img className="serv-icon" src={require('../../assets/images/target/a4.webp')} alt="" />
                                </span>
                                <h5 className="serv-title">500+</h5>
                                <p className="serv-para">Persons hospitalisation expenses to be taken care of on pan India basis  </p>
                            </div>
                        </div>
                        <div className="col-md-4 wow bounceInRight" data-wow-duration="1.5s" style={bounceInRightStyle}>
                            <div className="wrapper">
                                <span className="item-icon">
                                    <img className="serv-icon" src={require('../../assets/images/target/a5.webp')} alt="" />
                                </span>
                                <h5 className="serv-title">40000+</h5>
                                <p className="serv-para">products to be sold which will be made by weaker section of people in society to support them</p>

                                {/* <!--    <a className="read-btn" href="#">Read More</a> --> */}
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="winklix-foundation">
                <div className="container">
                    <h3>why WINKLIX FOUNDATION ?</h3>
                    <div className="row mb-80">
                        <div className="col-sm-3 wow zoomIn" style={zoomInStyle}>
                            <div className="foundation">
                                <img src={require('../../assets/images/why/f1.webp')} alt="" />
                                <h2>India's best fundraising platform</h2>
                            </div>
                        </div>
                        <div className="col-sm-3 wow zoomIn" style={zoomInStyle}>
                            <div className="foundation">
                                <img src={require('../../assets/images/why/f2.webp')} alt="" />
                                <h2>Supported By 100+ Donors in just 2 months</h2>
                            </div>
                        </div>
                        <div className="col-sm-3 wow zoomIn" style={zoomInStyle}>
                            <div className="foundation">
                                <img src={require('../../assets/images/why/f3.webp')} alt="" />
                                <h2>Always their to help you if you are in need</h2>
                            </div>
                        </div>

                        <div className="col-sm-3 wow zoomIn" style={zoomInStyle}>
                            <div className="foundation">
                                <img src={require('../../assets/images/why/f4.webp')} alt="" />
                                <h2>Pay donations via all popular payment modes</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 wow zoomIn" style={zoomInStyle}>
                            <div className="foundation">
                                <img src={require('../../assets/images/why/f5.webp')} alt="" />
                                <h2>Get Expert Support 24/7</h2>
                            </div>
                        </div>
                        <div className="col-sm-3 wow zoomIn" style={zoomInStyle}>
                            <div className="foundation">
                                <img src={require('../../assets/images/why/f6.webp')} alt="" />
                                <h2>A dedicated smart-dashboard to track your donations</h2>
                            </div>
                        </div>
                        <div className="col-sm-3 wow zoomIn" style={zoomInStyle}>
                            <div className="foundation">
                                <img src={require('../../assets/images/why/f7.webp')} alt="" />
                                <h2>Crowdfunding with ease</h2>
                            </div>
                        </div>
                        <div className="col-sm-3 wow zoomIn" style={zoomInStyle}>
                            <div className="foundation">
                                <img src={require('../../assets/images/why/f8.webp')} alt="" />
                                <h2>International Payment Acceptance</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Stories Update */}
            <section>
                <div className="productArea">
                    <div className="container-fluid laptop-view-stories-update">
                        <h3>Stories &amp; Updates</h3>
                        <div className="owl-carousel owl-loaded owl-drag">
                            <div className="row">
                                <Swiper
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 1,
                                            spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 1,
                                            spaceBetween: 30,
                                        },
                                        1024: {
                                            slidesPerView: 1.5,
                                            spaceBetween: 30,
                                        },
                                        1440: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                        2560: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="testimonialSwiper-blog-service"
                                >

                                    <SwiperSlide className="col-md-4">
                                        <div className="owl-item active center">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s1.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="col-md-4">
                                        <div className="owl-item active center">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s2.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="col-md-4">
                                        <div className="owl-item active center">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s3.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="col-md-4">
                                        <div className="owl-item active center">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s4.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="col-md-4">
                                        <div className="owl-item active center">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s5.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="col-md-4">
                                        <div className="owl-item active center">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s6.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-view-stories-update">
                        <h3>Stories &amp; Updates</h3>
                        <div className="container">
                            <div className="row">
                                <Swiper
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 1,
                                            spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="testimonialSwiper-blog-service"
                                >

                                    <SwiperSlide className="col-md-4">
                                        <div className="blog-container">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s1.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="col-md-4">
                                        <div className="blog-container">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s2.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="col-md-4">
                                        <div className="blog-container">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s3.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="col-md-4">
                                        <div className="blog-container">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s4.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="col-md-4">
                                        <div className="blog-container">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s5.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="col-md-4">
                                        <div className="blog-container">
                                            <div className="row mr-0">
                                                <div className="item">
                                                    <div className="updates">
                                                        <div className="col-sm-6">
                                                            <div className="stories">
                                                                <h2>Protecting Needy Communities During COVID-19</h2>
                                                                <p>From relief measures like distributing
                                                                    masks to providing them with hospitalisation and PPE kits , we have been helping many to sustain .</p>
                                                                <a className="knowmore" href="https://rzp.io/l/winklixfoundation">Donate Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pd-0">
                                                            <div className="stories-img">
                                                                <img src={require('../../assets/images/stories/s6.webp')} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contribute Today */}

            <section className="AwesomeArea">

                <div className="container laptop-view-contribute-section">
                    <h3 className="contribution-heading">How do you want to contribute today?</h3>
                    <p className="contribution">Even your smallest contribution can make huge difference in someone life . We count on people of India to bring real change in life of needy !</p>

                    <div className="row">
                        <Swiper
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 1.5,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 2.5,
                                    spaceBetween: 30,
                                },
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="testimonialSwiper-blog-service-contribute"
                        >

                            <SwiperSlide className="col-md-4">
                                <div className="blog-container">
                                    <div className="owl-item cloned active" style={{ width: '444px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn1.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN PROVING CLOTHES TO POOR SECTION OF SOCIETY </h3>
                                                <p>You can donate your old clothers and accessories which can be further donated to poor person for their use. </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="col-md-4">
                                <div className="blog-container">
                                    <div className="owl-item cloned active" style={{ width: '444px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn2.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN BUILDING WINKLIX HOSPITAL</h3>
                                                <p>You can help us in building Winklix Hospital - A hospital to provide private like facilities to people of India at minimum rates and best doctors in house</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="col-md-4">
                                <div className="blog-container">
                                    <div className="owl-item cloned active" style={{ width: '444px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn3.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN BUILDING WINKLIX HOSPITAL</h3>
                                                <p>You can help us in building Winklix Hospital - A hospital to provide private like facilities to people of India at minimum rates and best doctors in house</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="col-md-4">
                                <div className="blog-container">
                                    <div className="owl-item cloned active" style={{ width: '444px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn4.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN BUILDING WINKLIX HOSPITAL</h3>
                                                <p>You can help us in building Winklix Hospital - A hospital to provide private like facilities to people of India at minimum rates and best doctors in house</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="col-md-4">
                                <div className="blog-container">
                                    <div className="owl-item cloned active" style={{ width: '444px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn1.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN BUILDING WINKLIX HOSPITAL</h3>
                                                <p>You can help us in building Winklix Hospital - A hospital to provide private like facilities to people of India at minimum rates and best doctors in house</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="col-md-4">
                                <div className="blog-container">
                                    <div className="owl-item cloned active" style={{ width: '444px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn2.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN BUILDING WINKLIX HOSPITAL</h3>
                                                <p>You can help us in building Winklix Hospital - A hospital to provide private like facilities to people of India at minimum rates and best doctors in house</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <a className="readmore" target="_blank" href="https://rzp.io/l/winklixfoundation">Yes I Want To Help !</a>
                </div>

                <div className="container mobile-view-contribute-section">
                    <h3 className="contribution-heading">How do you want to contribute today?</h3>
                    <p className="contribution">Even your smallest contribution can make huge difference in someone life . We count on people of India to bring real change in life of needy !</p>
                    <div className="row">
                        <Swiper
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="testimonialSwiper-blog-service-contribute"
                        >
                            <SwiperSlide className="col-md-4">
                                <div className="blog-container" style={{ marginLeft: '20px' }}>
                                    <div className="owl-item cloned active" style={{ width: '315px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn1.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN PROVING CLOTHES TO POOR SECTION OF SOCIETY </h3>
                                                <p>You can donate your old clothers and accessories which can be further donated to poor person for their use. </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="col-md-4">
                                <div className="blog-container" style={{ marginLeft: '20px' }}>
                                    <div className="owl-item cloned active" style={{ width: '315px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn2.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN PROVING CLOTHES TO POOR SECTION OF SOCIETY </h3>
                                                <p>You can donate your old clothers and accessories which can be further donated to poor person for their use. </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="col-md-4">
                                <div className="blog-container" style={{ marginLeft: '20px' }}>
                                    <div className="owl-item cloned active" style={{ width: '315px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn3.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN PROVING CLOTHES TO POOR SECTION OF SOCIETY </h3>
                                                <p>You can donate your old clothers and accessories which can be further donated to poor person for their use. </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="col-md-4">
                                <div className="blog-container">
                                    <div className="owl-item cloned active" style={{ width: '315px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn4.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN PROVING CLOTHES TO POOR SECTION OF SOCIETY </h3>
                                                <p>You can donate your old clothers and accessories which can be further donated to poor person for their use. </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="col-md-4">
                                <div className="blog-container" style={{ marginLeft: '20px' }}>
                                    <div className="owl-item cloned active" style={{ width: '315px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn1.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN PROVING CLOTHES TO POOR SECTION OF SOCIETY </h3>
                                                <p>You can donate your old clothers and accessories which can be further donated to poor person for their use. </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="col-md-4">
                                <div className="blog-container" style={{ marginLeft: '20px' }}>
                                    <div className="owl-item cloned active" style={{ width: '315px', marginRight: '20px' }}><div className="item">
                                        <div className="quality">
                                            <img src={require('../../assets/images/contribute/dn2.webp')} alt="" />
                                            <div className="contentarea">
                                                <h3 className="quality-title">HELP US IN PROVING CLOTHES TO POOR SECTION OF SOCIETY </h3>
                                                <p>You can donate your old clothers and accessories which can be further donated to poor person for their use. </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <a className="readmore" target="_blank" href="https://rzp.io/l/winklixfoundation">Yes I Want To Help !</a>
                </div>
            </section>

            {/* Donate */}

            <div className="donate">
                <img src={require('../../assets/images/donatep.webp')} alt="" />
            </div>

            {/* Happy Client */}
            <section className="happy-client">
                <div className="laptop-view-happy-client">
                    <div className="container">
                        <h3>How You Can Donate ? </h3>
                        <div className="row">
                            <div className="donation">
                                <div className="col-sm-4 wow bounceInLeft" data-wow-duration="1.5s" style={bounceInLeftStyle}>
                                    <div className="ContactInfo purple">
                                        <img src={require('../../assets/images/donate/d1.webp')} alt="" />
                                        <h5>One Time / Monthly Contribution</h5>
                                    </div>
                                </div>
                                <div className="col-sm-4 wow zoomIn" style={{ visibility: 'visible' }}>
                                    <div className="ContactInfo orange">
                                        <img src={require('../../assets/images/donate/d2.webp')} alt="" />
                                        <h5>Project Sponsorship</h5>
                                    </div>
                                </div>
                                <div className="col-sm-4 wow bounceInRight" data-wow-duration="1.5s" style={bounceInRightStyle}>
                                    <div className="ContactInfo green">
                                        <img src={require('../../assets/images/donate/d3.webp')} alt="" />
                                        <h5>In Kind Donation</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-view-happy-client">
                    <div className="container">
                        <h3>How You Can Donate ? </h3><br />
                        <div className="row">
                            <Swiper
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 30,
                                    },
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                className="testimonialSwiper-blog-service-contribute"
                            >
                                <SwiperSlide className="col-md-4">
                                    <div className="owl-item active center">
                                        <div className="col-sm-4 wow bounceInLeft" data-wow-duration="1.5s" style={bounceInLeftStyle}>
                                            <div className="ContactInfo purple">
                                                <img src={require('../../assets/images/donate/d1.webp')} alt="" />
                                                <h5>One Time / Monthly Contribution</h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="col-md-4">
                                    <div className="owl-item active center">
                                        <div className="col-sm-4 wow zoomIn" style={{ visibility: 'visible' }}>
                                            <div className="ContactInfo orange">
                                                <img src={require('../../assets/images/donate/d2.webp')} alt="" />
                                                <h5>Project Sponsorship</h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="col-md-4">
                                    <div className="owl-item active center">
                                        <div className="col-sm-4 wow bounceInRight" data-wow-duration="1.5s" style={bounceInRightStyle}>
                                            <div className="ContactInfo green">
                                                <img src={require('../../assets/images/donate/d3.webp')} alt="" />
                                                <h5>In Kind Donation</h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home;