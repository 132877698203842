import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <>
            <section className="footer-top">
                <div className="container">
                    <div className="col-sm-3">
                        <div className="footer-logo">
                            <img src={require('../../assets/images/f-logo.webp')} alt="footer-logo" />
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="footer-top-content">
                            <p>Winklix aims to deploy the best possible methodology and technology to
                                achieve<br /> SROI ( Social Return On Investment ) in order to promote good governance .</p>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="footer-top--right">
                            <ul className="social-media">

                                <li><a href="https://www.instagram.com/winklixfoundationindia/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                <li><a href="https://www.twitter.com/winklixfoundation" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="https://www.facebook.com/winklixfoundation" target="_blank"><i className="fa fa-facebook"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="footer">
                <div className="container">
                    <div className="row mr-15">
                        <div className="col-lg-3 col-md-3 col-sm-4 col-12 col-xs-12 pd-0">
                            <h3 className="ftr-head mt-xs-20">Get to know us</h3>
                            <ul className="list-unstyled footer-txt">
                                <li><a href="/about" title="" target="_blank" rel="">About Us</a></li>
                                <li><a href="https://www.biatconsultant.com" title="" target="_blank" rel="">BIAT Consultant</a>
                                </li>
                                <li><a href="/blog" title="" target="_blank" rel="">Blog</a>
                                </li>
                                <li><a href="https://www.winklix.com" title="" target="_blank" rel="">Winklix.com</a>
                                </li>


                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-4 col-12 col-xs-12">
                            <h3 className="ftr-head mt-xs-20">Connect with us</h3>
                            <ul className="list-unstyled footer-txt">
                                <li><a href="/contact" title="" target="_blank" rel="">Contact Us</a></li>
                                {/* <!-- <li><a href="#" title="" target="_blank" rel="">Help Centre</a>
                                </li>
                                <li><a href="#" title="" target="_blank" rel=""> News Letter</a>
                                </li> --> */}

                            </ul>
                        </div>
                        {/* <!--  <div className="col-lg-3 col-md-3 col-sm-4 col-12 col-xs-12">
                            <h3 className="ftr-head mt-xs-20">Get Involved</h3>
                            <ul className="list-unstyled footer-txt">
                                <li><a href="#" title="" target="_blank">Corporate Partnership</a></li>
                                <li><a href="#" title="" target="_blank">Brand & Retail Partnership</a></li>
                                <li><a href="#" title="" target="_blank">Fundraise</a></li>


                            </ul>
                        </div> --> */}
                        {/* <!-- <div className="col-lg-3 col-md-3 col-sm-4 col-12 col-xs-12">
                            <h3 className="ftr-head mt-xs-20">Donate Now</h3>
                            <ul className="list-unstyled footer-txt">
                                <li><a href="#" title="" target="_blank">Covid Missions</a></li>
                                <li><a href="#" title="" target="_blank">Monthly Giving</a></li>
                                <li><a href="#" title="" target="_blank">Fundraisers</a></li>
                            </ul>
                        </div> --> */}
                        <div className="clearfix visible-sm"></div>


                    </div>
                </div>
            </section>

            <div className="copyrights">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-2">
                            <p><b>© Winklix Foundation 2024 </b></p>
                        </div>
                        <div className="col-sm-6">
                            <p><b> Donations through this platform are tax exempted under 80G &amp; 501(c)(3) </b>  </p>
                        </div>
                        <div className="col-sm-4">
                            <ul className="condition">
                                <li><Link to="/terms"><b>Terms &amp; Condition</b></Link></li>
                                <li><Link to="/privacy"><b>Privacy</b></Link></li>
                                <li><a href="sitemap.xml"><b>Sitemap</b></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;