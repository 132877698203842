// let { BrowserRouter, Switch, Route, NavLink } = ReactRouterDOM;
import { NavLink } from "react-router-dom";
import { useState } from "react";

const Header = () => {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const Close = () => setClick(false);

    return (
        <div>
            <div className="UpHeader">
                <div className="col-sm-6">
                </div>

                <div className="col-sm-6">
                    <div className="UpHeaderRight">
                        <ul>
                            <li><a target="_blank" href="https://www.twitter.com/winklixfoundation"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                            <li><a target="_blank" href="https://www.instagram.com/winklixfoundationindia/"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                            <li><a target="_blank" href="https://www.facebook.com/winklixfoundation"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                            <li><a target="_blank" className="donate" href="https://rzp.io/l/winklixfoundation">DONATE NOW</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={click ? "main-container" : ""} onClick={() => Close()} />
            <div className="laptop-view-menu">
                <nav className="navbar" onClick={e => e.stopPropagation()}>
                    <div className="nav-container">
                        <NavLink exact to="/" className="nav-logo">
                            <img src={require('../../assets/images/logo-foundation.webp')} style={{ height: '66px', marginTop: '13px' }} alt="winklix_foundation" />
                        </NavLink>
                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={click ? handleClick : null}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/about"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={click ? handleClick : null}
                                >
                                    About
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/shop"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={click ? handleClick : null}
                                >
                                    Shop
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/our-programs"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={click ? handleClick : null}
                                >
                                    Our Programs
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/crowdfunding"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={click ? handleClick : null}
                                >
                                    Crowdfunding
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/blog"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={click ? handleClick : null}
                                >
                                    Blog
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    target="_blank"
                                    to="https://forms.gle/k7HMFgki6zqwoieW7"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={click ? handleClick : null}
                                >
                                    Join Us
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/contact"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={click ? handleClick : null}
                                >
                                    Contact Us
                                </NavLink>
                            </li>
                        </ul>
                        <div className="nav-icon" onClick={handleClick}>
                            <i className={click ? "fa fa-times" : "fa fa-bars"} style={{ fontSize: '30px' }}></i>
                        </div>
                    </div>
                </nav >
            </div>

            <div className="mobile-view-menu">
                <nav className="navbar" onClick={e => e.stopPropagation()}>
                    <div className="nav-container">
                        {/* <div className="nav-icon" onClick={handleClick}>
                            <i className={click ? "fa fa-times" : "fa fa-bars"} style={{ fontSize: '30px' }}></i>
                        </div> */}
                        <nav className="nav">
                            <input type="checkbox" id="nav-check" />
                            <img src={require('../../assets/images/logo-foundation.png')} style={{ height: '66px', marginTop: '13px' }} alt="winklix_foundation" />
                            <div className="nav-btn">
                                <label for="nav-check">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </label>
                            </div>
                            <ul className="nav-list active" style={{ textAlign: 'left', paddingLeft: '12px' }}>
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="/about">About</NavLink></li>
                                <li><NavLink to="/shop">Shop</NavLink></li>
                                <li><NavLink to="/our-programs"> Our Programs</NavLink></li>
                                <li><NavLink to="/crowdfunding">Crowdfunding</NavLink></li>
                                <li><a target="_blank" href="https://forms.gle/k7HMFgki6zqwoieW7">Join Us</a></li>
                                <li><NavLink to="/blog">Blog</NavLink></li>
                                <li><NavLink to="/contact">Contact Us</NavLink></li>

                            </ul>
                        </nav>
                    </div>
                </nav >
            </div>
        </ div >
    );
}

export default Header;