const Contact = () => {

    const style = {
        width: '250px',
        height: '40px',
        border: '1px solid rgb(193, 193, 193)',
        margin: '10px 25px',
        padding: '0px',
        resize: 'none',
        display: 'none'
    };

    return (
        <>
            <section>
                <div className="ContactArea">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="ContactRight">
                                    <div className="contact-title-wrapper">
                                        <img src={require('../../assets/images/contact/contact-hero-icon.webp')} alt="" />
                                        <div className="contact-title-block">
                                            <h1 className="hero-title">Write To Us</h1>
                                            <p>If you have any queries, visit our FAQs section or just send us a message and someone from our team will reach out to you.</p>
                                        </div>
                                        <div className="contact-faq-title-block">
                                            <img src={require('../../assets/images/contact/faq-chat.webp')} alt="" />
                                            <div>
                                                <h3>Frequently Asked Questions</h3>
                                                <p><a className="btn btn-text" href="#">Making A Donation</a><a className="btn btn-text" href="#">Volunteering &amp; Internships</a></p>
                                            </div>
                                        </div>
                                        <div className="clear">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="ContactLeft">
                                    <h3 style={{ textAlign: 'left' }}>Send a Message</h3>
                                    <p style={{ textAlign: 'left' }}>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form,</p>
                                    <p className="">
                                    </p>
                                    <div id="errorHtml" style={{ color: 'red' }}></div>
                                    <form action="" method="post" id="contactInfo">
                                        <div className="form-group col-sm-6">
                                            <label>Name</label>
                                            <div className="input-group">
                                                <span className="input-group-addon"><i className="fa fa-info" aria-hidden="true"></i></span>
                                                <input type="text" className="form-control" placeholder="Enter Name" id="name" name="name" />
                                            </div>
                                            <p className="error hidden">Please enter name!</p>
                                        </div>

                                        <div className="form-group col-sm-6">
                                            <label>Email <span>*</span></label>
                                            <div className="input-group">
                                                <span className="input-group-addon"><i className="fa fa-info" aria-hidden="true"></i></span>
                                                <input type="email" className="form-control" placeholder="Your Email" name="email" required="" id="email" />
                                            </div>
                                            <p className="error hidden">Please enter valid email!</p>
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label>Phone No <span>*</span></label>
                                            <div className="input-group">
                                                <span className="input-group-addon"><i className="fa fa-info" aria-hidden="true"></i></span>
                                                <input type="number" className="form-control" placeholder="Phone Number" name="phone" required="" id="phone" />
                                            </div>
                                            <p className="error hidden">Please enter valid phone!</p>
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label>Location <span>*</span></label>
                                            <div className="input-group">
                                                <span className="input-group-addon"><i className="fa fa-info" aria-hidden="true"></i></span>
                                                <input type="text" className="form-control" placeholder="Location" name="location" required="" id="location" />
                                            </div>
                                            <p className="error hidden">Please enter location!</p>
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label>Subject <span>*</span></label>
                                            <div className="input-group">
                                                <span className="input-group-addon"><i className="fa fa-info" aria-hidden="true"></i></span>
                                                <input type="text" className="form-control" placeholder="Subject" name="subject" required="" id="subject" />
                                            </div>
                                            <p className="error hidden">Please enter subject!</p>
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label>Your Message <span>*</span></label>
                                            <div className="input-group">
                                                <span className="input-group-addon"><i className="fa fa-info" aria-hidden="true"></i></span>
                                                <textarea className="form-control" rows="5" id="comment" placeholder="Your Message" name="comment" required=""></textarea>
                                            </div>
                                            <p className="error hidden">Please enter message!</p>
                                        </div>
                                        <div className="g-recaptcha" data-sitekey="6LfIh_0bAAAAAAOjHrhTacOIDh7eB2L51qAKzIql"><div style={{ width: '304px', height: '78px' }}>
                                            <div>
                                                <iframe title="reCAPTCHA" width="304" height="78" role="presentation" name="a-clxs2qrr6u7u" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                                                    src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LfIh_0bAAAAAAOjHrhTacOIDh7eB2L51qAKzIql&amp;co=aHR0cHM6Ly93aW5rbGl4Zm91bmRhdGlvbi5vcmc6NDQz&amp;hl=en&amp;v=rKbTvxTxwcw5VqzrtN-ICwWt&amp;size=normal&amp;cb=lpsapjlsxm7f"></iframe>
                                            </div>
                                            <textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response" style={style}>
                                            </textarea>
                                        </div><iframe style={{ display: 'none' }}></iframe></div>
                                        <div className="clearfix">
                                        </div>
                                        <div className="form-group col-sm-12" style={{ marginTop: '100px' }}>
                                            <a href="javaScript:void(0)" className="btn btn-success" onclick="send_message()"> Send Message</a>

                                        </div>
                                    </form>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="ContactFrame">
                    <div className="container">

                        <div className="row">
                            <div className="col-sm-4">
                                <div className="ContactInfo1">
                                    <img src={require('../../assets/images/contact/phone.webp')} alt="" />
                                    <h5>Phone Number</h5>
                                    <p>+91 9582089971 <br /> +91 8368482950 </p>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="ContactInfo1">
                                    <img src={require('../../assets/images/contact/mail.webp')} alt="" />
                                    <h5>Email &amp; Web</h5>
                                    <p>info@winklixfoundation.com <br /> www.winklixfoundation.com</p>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="ContactInfo1">
                                    <img src={require('../../assets/images/contact/map-marker.webp')} alt="" />
                                    <h5>Our Address</h5>
                                    <p>14/B, Street City, Sector 62, <br /> Noida, Uttar Pradesh </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;